
<template>
    <div :class="getMobileFooter">
        <ul>
            <li @click="routerPush('/uuid')">
                <svg t="1686983703008" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="2390" width="20" height="20">
                    <path
                        d="M512 73.770667c2.730667 0 7.978667 0.668444 12.472889 5.162666l390.115555 390.115556H865.564444V906.780444c0 10.197333-8.305778 18.503111-18.503111 18.503112H657.792v-129.578667c0-80.398222-65.408-145.792-145.792-145.792-80.398222 0-145.792 65.408-145.792 145.792v129.578667H176.938667a18.531556 18.531556 0 0 1-18.503111-18.503112V469.048889H109.411556l390.115555-390.115556c4.494222-4.494222 9.742222-5.162667 12.472889-5.162666M512 28.444444a62.72 62.72 0 0 0-44.515556 18.446223L0 514.360889h113.109333v392.405333a63.815111 63.815111 0 0 0 63.815111 63.815111h234.595556V795.690667a100.48 100.48 0 1 1 200.96 0v174.904889H847.075556a63.815111 63.815111 0 0 0 63.815111-63.815112V514.360889H1024L556.515556 46.890667A62.72 62.72 0 0 0 512 28.444444z"
                        :fill="indexActive" p-id="2391"></path>
                </svg>
                <span :style="'color:' + indexActive">AI对话</span>
            </li>
            <li @click="routerPush('/app')" v-if="authStore.menuList.includes('/app')">
                <svg t="1686983812230" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="3579" width="20" height="20">
                    <path
                        d="M864 874.666667a32 32 0 0 1 0 64h-704a32 32 0 0 1 0-64h704zM853.333333 85.333333a128 128 0 0 1 128 128v469.333334a128 128 0 0 1-128 128H170.666667a128 128 0 0 1-128-128V213.333333a128 128 0 0 1 128-128h682.666666z m0 64H170.666667a64 64 0 0 0-63.893334 60.245334L106.666667 213.333333v469.333334a64 64 0 0 0 60.245333 63.893333L170.666667 746.666667h682.666666a64 64 0 0 0 63.893334-60.245334L917.333333 682.666667V213.333333a64 64 0 0 0-60.245333-63.893333L853.333333 149.333333z m-41.429333 126.656a32 32 0 0 1 2.304 42.816l-2.197333 2.432-212.373334 213.333334a32 32 0 0 1-42.773333 2.346666l-2.432-2.197333L448 429.162667l-190.72 190.805333a32 32 0 0 1-42.837333 2.197333l-2.432-2.197333a32 32 0 0 1-2.197334-42.837333l2.197334-2.432 213.269333-213.333334a32 32 0 0 1 42.752-2.261333l2.432 2.176 106.304 105.493333 189.866667-190.677333a32 32 0 0 1 45.248-0.106667z"
                        :fill="aiActive" p-id="3580"></path>
                </svg>
                <span :style="'color:' + aiActive">AI应用</span>
            </li>
            <li @click="routerPush('/draw')" v-if="authStore.menuList.includes('/draw')">
                <svg t="1692524770563" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="4606" width="20" height="20">
                    <path
                        d="M17.32377 384.32488C89.587313 108.182022 367.976854-56.252376 637.891568 17.717508c257.373107 70.585641 439.695647 324.176359 371.527322 584.535562-20.760483 79.28798-71.410373 146.887525-140.858453 187.925027a296.277683 296.277683 0 0 1-229.50287 30.884772l-87.250905-23.888774a29.690334 29.690334 0 0 0-36.401942 21.443019c-1.905414 6.967559-1.137561 14.390143 2.132926 20.788922l0.199073 0.483463 0.398147 0.824731 0.255951 0.483464c6.540974 13.935118 19.366971 47.379403 8.531705 88.84349a125.871091 125.871091 0 0 1-57.532132 76.842225 120.865824 120.865824 0 0 1-93.791879 12.570045C105.712236 945.398253-54.968212 660.553056 17.32377 384.32488z m596.280877-273.867737C393.771043 50.251744 166.856124 184.256393 107.958919 409.237459c-58.868766 224.952627 72.092909 457.157204 291.926513 517.419482 7.621657 2.132926 15.755216 1.080683 22.580579-2.957658s11.802192-10.693071 13.792924-18.5138a33.188333 33.188333 0 0 0-2.531073-22.58058 127.179286 127.179286 0 0 1-9.015168-88.815051c17.689069-67.457349 85.430808-107.499486 151.35245-89.412271l87.307783 23.888775a204.305901 204.305901 0 0 0 158.234693-21.272385 212.72385 212.72385 0 0 0 97.147683-129.539724c51.702134-197.508975-85.629881-406.734826-305.150656-466.968665z"
                        :fill="drawActive" p-id="4607"></path>
                    <path
                        d="M709.671647 505.30446c3.18517-11.603119 10.920583-21.528336 21.528337-27.528969a46.924379 46.924379 0 0 1 35.036869-4.521804l24.286921 6.398779c15.92585 4.180536 28.382139 16.409313 32.647992 32.050773 4.265853 15.64146-0.28439 32.335163-11.944388 43.796087s-28.694968 15.92585-44.620818 11.745314l-24.286921-6.398779a46.071208 46.071208 0 0 1-28.040871-21.13019 44.620818 44.620818 0 0 1-4.607121-34.411211z m-92.31305-226.658968c3.156731-11.631558 10.892144-21.528336 21.499897-27.557408a46.924379 46.924379 0 0 1 35.03687-4.521804l24.28692 6.398779a45.274916 45.274916 0 0 1 32.647992 55.541401c-6.597852 24.173165-31.937016 38.534868-56.565205 32.050773l-24.258482-6.398779a46.071208 46.071208 0 0 1-28.040871-21.13019 44.620818 44.620818 0 0 1-4.607121-34.411211zM385.324655 204.192144c3.18517-11.603119 10.920583-21.499897 21.528336-27.528968a46.924379 46.924379 0 0 1 35.036869-4.521804l24.286921 6.398779a45.274916 45.274916 0 0 1 32.619553 55.541401c-6.597852 24.173165-31.908577 38.534868-56.536767 32.050772l-24.28692-6.341901a46.071208 46.071208 0 0 1-28.06931-21.158629 44.620818 44.620818 0 0 1-4.550243-34.411211z m-184.65454 181.355614c3.156731-11.603119 10.892144-21.528336 21.499897-27.528969a46.924379 46.924379 0 0 1 35.03687-4.550243l24.28692 6.427218c15.92585 4.180536 28.382139 16.409313 32.647992 32.050773 4.265853 15.64146-0.28439 32.335163-11.944387 43.796087s-28.666529 15.92585-44.620818 11.745314l-24.258482-6.37034a46.071208 46.071208 0 0 1-28.06931-21.13019 44.620818 44.620818 0 0 1-4.578682-34.43965z"
                        :fill="drawActive" p-id="4608"></path>
                </svg>
                <span :style="'color:' + drawActive">AI绘画</span>
            </li>
            <li @click="routerPush('/user/task')" v-if="authStore.menuList.includes('/user/task')">
                <svg t="1690583436494" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="2576" width="20" height="20">
                    <path
                        d="M831.825 63.94H191.94c-70.692 0-128 57.308-128 128v639.885c0 70.692 57.308 128 128 128h639.885c70.692 0 128-57.308 128-128V191.94c0-70.692-57.308-128-128-128zM895.885 832a63.835 63.835 0 0 1-63.973 63.886H192.088c-17.112 0-33.27-6.575-45.372-18.676s-18.836-28.098-18.836-45.21V192a64.236 64.236 0 0 1 64.208-64.12h639.824A64.038 64.038 0 0 1 895.885 192V832z"
                        p-id="2577" :fill="taskActive"></path>
                    <path
                        d="M791.998 351.852H536a31.97 31.97 0 0 0 0 63.94h256a31.97 31.97 0 0 0 0-63.94z m0 256.121H536a31.97 31.97 0 0 0 0 63.94h256a31.97 31.97 0 0 0 0-63.94z m-447.996-79.975c-61.856 0-111.986 50.144-111.986 111.985S282.16 751.97 344.002 751.97s111.985-50.144 111.985-111.986-50.13-111.985-111.985-111.985z m33.982 145.982a48.045 48.045 0 1 1 14.088-33.982 47.746 47.746 0 0 1-14.088 33.986z m39.412-376.586L311.999 402.787l-41.391-41.395a31.97 31.97 0 1 0-45.213 45.213l63.997 64.002a31.97 31.97 0 0 0 45.214 0l128-128a31.97 31.97 0 0 0-45.21-45.213z"
                        p-id="2578" :fill="taskActive"></path>
                </svg>
                <span :style="'color:' + taskActive">任务</span>
            </li>
            <li @click="routerPush('/user')" v-if="authStore.menuList.includes('/user')">
                <svg t="1686983883333" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="5067" width="20" height="20">
                    <path
                        d="M511.999488 66.861804c-245.880013 0-445.138196 199.284789-445.138196 445.138196 0 245.852384 199.258183 445.137173 445.138196 445.137173s445.138196-199.284789 445.138196-445.137173C957.137685 266.146593 757.879502 66.861804 511.999488 66.861804zM511.999488 901.494898c-214.799134 0-389.495922-174.723394-389.495922-389.494898 0-214.771505 174.696788-389.495922 389.495922-389.495922 214.798111 0 389.495922 174.724417 389.495922 389.495922C901.49541 726.771505 726.797599 901.494898 511.999488 901.494898z"
                        :fill="userActive" p-id="5068"></path>
                    <path
                        d="M596.92981 483.2277c49.556673-29.28907 83.191724-82.675978 83.191724-144.267754 0-92.727914-75.420737-168.17628-168.122045-168.17628-92.755544 0-168.17628 75.448366-168.17628 168.17628 0 61.591777 33.689287 114.979708 83.191724 144.267754-105.144712 35.700083-181.543729 134.297682-181.543729 251.368004 0 15.377222 12.389168 27.821649 27.821649 27.821649 15.32401 0 27.821649-12.443403 27.821649-27.821649 0-116.2834 94.548376-210.886011 210.886011-210.886011s210.886011 94.603635 210.886011 210.886011c0 15.377222 12.498662 27.821649 27.821649 27.821649 15.432481 0 27.821649-12.443403 27.821649-27.821649C778.527774 617.525382 702.128757 518.927783 596.92981 483.2277zM511.999488 451.467345c-62.000076 0-112.534006-50.480718-112.534006-112.5074 0-62.054311 50.53393-112.534006 112.534006-112.534006s112.479771 50.480718 112.479771 112.534006C624.479259 400.986627 573.999564 451.467345 511.999488 451.467345z"
                        :fill="userActive" p-id="5069"></path>
                </svg>
                <span :style="'color:' + userActive">个人中心</span>
            </li>
        </ul>
    </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/store'
const authStore = useAuthStore()
const route = useRoute()
const router = useRouter()
const { isMobile } = useBasicLayout()

const routerPush = (path: any) => {
    router.push({
        path
    })
}

const indexActive = computed(() => {
    return route.name === 'Chat' ? 'rgb(255, 153, 0)' : '#333'
})

const aiActive = computed(() => {
    return route.name === 'ChatApp' ? 'rgb(255, 153, 0)' : '#333'
})

const userActive = computed(() => {
    return route.name && route.name.indexOf('ChatUser') > -1 ? 'rgb(255, 153, 0)' : '#333'
})

const drawActive = computed(() => {
    return route.name && route.name.indexOf('Draw') > -1 ? 'rgb(255, 153, 0)' : '#333'
})

const taskActive = computed(() => {
    return route.name && route.name.indexOf('Task') > -1 ? 'rgb(255, 153, 0)' : '#333'
})

const getMobileFooter = computed(() => {
    if (isMobile.value) {
        return 'mobile-footer'
    } else {
        return 'pc-footer'
    }
})
</script>
<style lang="less" scoped>
.mobile-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;

    ul {
        display: flex;
        padding-top: 5px;
        justify-content: space-between;
        li {
            width: 33.333%;
            text-align: center;
            cursor: pointer;
            span {
                font-size: 12px;
                letter-spacing: -1px;
            }
            .icon {
                margin: 0 auto;
            }
        }
    }
}

.pc-footer {
    position: fixed;
    bottom: 0;
    top: 0;
    width: 64px;
    left: 0;
    background: #fff;
    padding-top: 30px;

    li {
        height: 80px;
        padding-top: 10px;
        text-align: center;
        cursor: pointer;

        .icon {
            margin: 0 auto;
        }
    }
}</style>