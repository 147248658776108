
/*
 * @Author: mjjh
 * @LastEditTime: 2024-09-04 23:09:54
 * @FilePath: \chatgpt-shuowen\src\store\modules\auth\index.ts
 * @Description: 存储用户数据
 */
import { defineStore } from 'pinia'
import { getToken, removeToken, setToken } from './helper'
import { getMenu } from '@/api/login'
import { store } from '@/store'
import aa from '../../../assets/aa.jpg';
import output from  '../../../assets/output.png';
const menuUrlOptions = [
  {
    label: '创作',
    value: '/ai'
  },
  // {
  //   label: '绘画',
  //   value: '/draw'
  // },
  {
    label: '任务',
    value: '/user/task'
  },
  {
    label: '个人中心',
    value: '/user'
  },
  {
    label: '个人中心-充值',
    value: '/user/pay'
  },
  {
    label: '个人中心-卡密兑换',
    value: '/user/card'
  },
  {
    label: '个人中心-修改用户信息',
    value: '/user/edit'
  },
  {
    label: '个人中心-订单记录',
    value: '/user/order'
  },
  {
    label: '个人中心-联系客服',
    value: '/user/wx'
  },{
    label: '分销赚钱',
    value: '/team'
  },
  {
    value: '/team/fans'
  },
  {
    value: '/team/order'
  },  {
    value: '/team/share'
  },  {
    value: '/team/withdraw'
  },  {
    value: '/team/withdraw/list'
  },  {
    value: '/team/withdraw/detail'
  }
].map(item => item.value)

export interface AuthState {
  token: string | undefined,
  menuList: any,
  webConfig: any
}

export const useAuthStore = defineStore('auth-store', {
  state: (): AuthState => ({
    token: getToken(),
    menuList: JSON.parse(localStorage.getItem('menuList') || '[]'),
    webConfig: {
      title: '',
      indexPage: {
        title: "",
        content: ``,
      },
      indexDialogConfig: {
        title: "",
        wxCode: '',
      },
      noCountConfig: {
        title: "",
        wxCode: output,
      },
      kefuConfig: {
        title: "",
        wxCode: aa,
      },
    },
  }),

  actions: {

    setToken(token: string) {
      this.token = token
      setToken(token)
    },
    setWebConfig(data) {
			this.webConfig = {...this.webConfig, ...data}
		},
    async getMenuList(route) {
      const { data: menuData } = await getMenu()
      const menuUrl = menuData.userMenuVOList?.map(item =>item.menuUrl)??[]

      this.setMenuList((!menuData.roleId ? menuUrlOptions : menuUrl))
      const roleData = menuData.userSysInfoList?.[0]??{}
      const config = {
        title: roleData.siteName || '',
        isLoad: true,
        siteLogoUrl: roleData.siteLogoUrl,
        isPopUpTitle: roleData.isPopUpTitle,
        indexPage: {
          title: roleData.adTitle || '恒洋瓦AI',
          content: roleData.adContent || `<span style="color:#333;" data-v-09421957=""> 您好，我是恒洋瓦AI大语言模型，恭喜您获得恒洋瓦AI的内测资格。
          这意味着您现在站在了探索人工智能新时代的前沿，您现在正在打开新一代人工智能的大门，
          这是一个探索和发现的过程，您的参与将会使这个过程更加丰富和有趣。<br data-v-09421957=""><br data-v-09421957=""> 
          恒洋瓦AI建立在先进的语言大模型基础之上，我能够学习和理解人类的语言，进行多轮对话<br data-v-09421957=""> 回答问题，
          高效便捷地帮助人们获取信息、知识和灵感。<br data-v-09421957=""><br data-v-09421957=""> 使用恒洋瓦AI，我们希望您需要知晓以下几点：
          <br data-v-09421957=""> 1、恒洋瓦AI是一项公益项目，使用恒洋瓦Ai进需要消耗对话次数。<br data-v-09421957=""> 
          2、初始注册时，我们将免费赠送您50次的对话额度。<br data-v-09421957=""> 3、对话次数仅通过"恒洋瓦教育"知识产品赠送，配合教学使用。<br data-v-09421957=""> 
          4、如果您在使用过程中遇到任何问题，欢迎添加微信：<small style="font-weight:bold;color:rgb(255, 153, 0);" data-v-09421957="">Darenwu2023</small>，
          申请加入AIGC社群。<br data-v-09421957=""> 5、恒洋瓦AI工具的设计初衷是用于学习和交流，请不要将其用于非法用途，感谢您的理解和支持。
          <br data-v-09421957=""><br data-v-09421957=""> 在这个充满无限可能的AI时代，我们期待您能发挥想象力，与恒洋瓦AI一起创造更美好的未来。
          <br data-v-09421957=""> 无论您的目标有多高，我都将全力以赴，成为您实现梦想的得力助手。让我们一起开启这段美好的人工智能之旅吧！<br data-v-09421957=""></span>`
        },
        indexDialogConfig: {
          title: roleData.popUpTitle || '长按识别二维码关注',
          wxCode: roleData.qrCodeUrl || aa
        },
        noCountConfig: {
          title: "您的额度为0，微信扫一扫加入会员获取更多额度",
          wxCode: roleData.noLimitQrCodeUrl || output
        },
        kefuConfig: {
          title:roleData.customerServiceWechat|| '长按识别二维码关注',
          wxCode: roleData.qrCodeUrl || aa
        }
      }
      document.title = `${roleData.siteName || ''} - ${route.meta?.title?.split(' - ')?.[1] || ''}`
      this.setWebConfig(config)
    },
    setMenuList(data) {
      this.menuList = data;
      localStorage.setItem('menuList', JSON.stringify(data))
    },
    removeMenuList() {
      localStorage.setItem('menuList', '[]')
    },

    removeToken() {
      this.token = undefined
      removeToken()
    },
  },
})

export function useAuthStoreWithout() {
  return useAuthStore(store)
}
