
<script setup lang='ts'>
import { ref, onMounted } from 'vue';
import type { FormInst, FormRules } from 'naive-ui'
import { NButton, NForm, NFormItem, NInput, useMessage } from 'naive-ui'
import { loginEmail } from '@/api/login'
import { useAuthStore } from '@/store'
const authStore = useAuthStore()
import { wxAuth, isWechatEnvironment } from '@/utils/is'

onMounted(() => {
  localStorage.removeItem('wxCode')
})
const ms = useMessage()
interface Props {
  openId: string
}

const props = defineProps<Props>()

const loading = ref(false)
// const token = ref('')
const emits = defineEmits(['login-success'])

// 表单相关
const formRef = ref<FormInst | null>(null)
const userInfo = ref({
  username: '',
  passWord: '',
  openID: props.openId
})

const rules: FormRules = {
  username: [
    {
      required: true,
      trigger: ['blur', 'input'],
      validator(rule, value) {
        // 自定义验证  规则
        if (!value)
          return new Error('请输入手机号/用户名')

        return true
      },
    }
  ],
  passWord: [
    {
      required: true,
      message: '请输入密码',
      trigger: ['input', 'blur'],
    },
  ],
}

/**
 * @description: 提交"token/secretKey"到后台校验
 */
async function handleVerify() {
  // 格式校验
  formRef.value?.validate((errors: any) => {
    if (!errors)
      pushClick()

    else ms.error('请正确填写输入框中的内容')
  })
}

async function pushClick() {
  // 取值
  const pushData: loginModel = {
    username: userInfo.value.username,
    password: userInfo.value.passWord,
    openID: props.openId
  }

  try {
    // 开启加载状态
    loading.value = true
    // 发起请求
    // 登录
    const { data } = await loginEmail(pushData)

    // 登录成功后 存token并且请求用户基础数据
    authStore.setToken(data?.token)
    await authStore.getMenuList({})
    emits('login-success')
    // 成功弹框
    ms.success('登录成功')
    // 重载页面
    // window.location.reload()
  }
  catch (error: any) {
    console.log(error, 'err')
    // 错误弹框
    // ms.error(error ?? 'error')
  }
  finally {
    // 关闭加载状态
    loading.value = false
  }
}

/**
 * @description: 监听键盘Enter事件
 * @param {*} event
 */
function handlePress(event: KeyboardEvent) {
  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault()
    handleVerify()
  }
}

function wxLogin() {
  wxAuth({})
}

</script>

<template>
  <NForm ref="formRef" :model="userInfo" label-placement="top" :rules="rules">
    <NFormItem path="username" label="手机号/用户名">
      <NInput v-model:value="userInfo.username" placeholder="请输入手机号/用户名" />
    </NFormItem>
    <NFormItem path="passWord" :label="$t('common.passWord')">
      <NInput v-model:value="userInfo.passWord" type="password" show-password-on="click" @keypress="handlePress" />
    </NFormItem>
  </NForm>
  <NButton block type="primary" :loading="loading" @click="handleVerify">
    {{ $t('common.verify') }}
  </NButton>
  <div style="display:flex;justify-content: center;padding:10px 0" v-if="isWechatEnvironment()">
    <svg @click="wxLogin" t="1689640869774" class="icon" viewBox="0 0 1024 1024" version="1.1"
      xmlns="http://www.w3.org/2000/svg" p-id="1534" width="50" height="50">
      <path
        d="M352.814545 385.396364m-33.512727 0a33.512727 33.512727 0 1 0 67.025455 0 33.512727 33.512727 0 1 0-67.025455 0Z"
        fill="#FFB814" p-id="1535"></path>
      <path
        d="M502.690909 384.465455m-33.512727 0a33.512727 33.512727 0 1 0 67.025454 0 33.512727 33.512727 0 1 0-67.025454 0Z"
        fill="#FFB814" p-id="1536"></path>
      <path
        d="M576.232727 534.341818m-23.272727 0a23.272727 23.272727 0 1 0 46.545455 0 23.272727 23.272727 0 1 0-46.545455 0Z"
        fill="#FFB814" p-id="1537"></path>
      <path
        d="M694.458182 536.203636m-23.272727 0a23.272727 23.272727 0 1 0 46.545454 0 23.272727 23.272727 0 1 0-46.545454 0Z"
        fill="#FFB814" p-id="1538"></path>
      <path
        d="M512 0C229.003636 0 0 229.003636 0 512s229.003636 512 512 512 512-229.003636 512-512S794.996364 0 512 0z m-87.505455 630.225455c-26.996364 0-48.407273-5.585455-75.403636-11.17091l-75.403636 37.236364 21.410909-64.232727c-53.992727-37.236364-85.643636-85.643636-85.643637-145.221818 0-102.4 96.814545-182.458182 215.04-182.458182 105.192727 0 198.283636 64.232727 216.901819 150.807273-6.516364-0.930909-13.963636-0.930909-20.48-0.93091-102.4 0-182.458182 76.334545-182.458182 170.356364 0 15.825455 2.792727 30.72 6.516363 44.683636-7.447273 0-13.963636 0.930909-20.48 0.93091z m314.647273 75.403636l15.825455 53.992727-58.647273-32.581818c-21.410909 5.585455-42.821818 11.170909-64.232727 11.170909-102.4 0-182.458182-69.818182-182.458182-155.461818s80.058182-155.461818 182.458182-155.461818c96.814545 0 182.458182 69.818182 182.458182 155.461818 0 47.476364-31.650909 90.298182-75.403637 122.88z"
        fill="#FFB814" p-id="1539"></path>
    </svg>
  </div>
  <div class="agree">
    <svg t="1692520135913" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
      p-id="4861" width="18" height="18">
      <path
        d="M512.1 62C263.9 61.9 62.3 263.4 62 511.7 61.7 759.8 263 961.4 511.5 962c248.3 0.6 450.5-201.5 450.5-450.1C962 263.7 760.4 62.1 512.1 62z m-76.3 638.2C373.2 637.6 310.3 574.7 248 512.5c11.4-11.4 23.2-23.1 34.7-34.5 49.4 49.5 100.1 100.1 150.6 150.7 103-103.1 204.6-204.7 305.5-305.6 13.4 13.4 25.5 25.5 37.2 37.1-112.8 112.7-226.7 226.5-340.2 340z"
        fill="#FFB814" p-id="4862"></path>
    </svg> 我已阅读并同意：<a href="/#/privacy">《隐私政策》</a>和<a href="/#/use">《使用协议》</a>
  </div>
</template>
<style lang="less" scoped>
.agree {
  padding: 10px;
  display: flex;

  a {
    color: #FFB814;
    cursor: pointer;
  }
}
</style>